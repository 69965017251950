/* eslint-disable no-undef */
const initBlog = () => {
	const searchWidget = document.querySelector('aside .wp-block-search');
	if (searchWidget) {
		searchWidget.innerHTML += '<input type="hidden" name="post_type" value="post">';
		searchWidget.action = `${orthofixBlog.blogURL}/blog`;
	}

	// Add current class to tag cloud.
	const tagCloudLinks = document.querySelectorAll('aside .tag-cloud-link');
	const bodyClasses = document.body.getAttribute('class');
	tagCloudLinks.forEach((link) => {
		const regex = /\d+/;
		const currentTagId = bodyClasses.match(regex);
		const linkTagId = link.getAttribute('class').match(regex);
		if (currentTagId[0] === linkTagId[0]) {
			link.classList.add('current');
		}
	});
};

document.addEventListener('DOMContentLoaded', initBlog);
